import {
  BaseAnalytics,
  BaseWarningCart,
  BeaconIds,
  CheckoutBaseAnalytics, ErrorAnalytics,
  OrderConfirmedAnalytics
} from '@/dataAnalytics/analyticsTypes';
import { CartItem } from '@/domain/cart/types';
import { CHANGE_OCCASION_TITLE } from './CheckoutPage/constants';
import { getCouponQuantity, getItemQuantityByType } from '@/checkout/helpers/getItemQuantity';
import {
  finalPromiseTime,
  getCouponInformation,
  getDealsInfo,
  getProductsInfo
} from '@/checkout/helpers/orderAnalytics';
import { EDIT_TITLE } from '@/clientCore/checkout/components/YourOrder/constants';

export const commonCheckout = (level: string) : Partial<BaseAnalytics> => ({
  screen_name: `Checkout > ${level}`,
  event_category: `Checkout > ${level}`,
  screen_level1: 'Checkout',
  screen_level2: `Checkout > ${level}`,
  screen_level3: `Checkout > ${level}`,
  screen_level4: `Checkout > ${level}`,
  level2_breadcrumb: `Checkout > ${level}`,
  level3_aggregation: `Checkout > ${level}`
});

export const commonBuilderEditor = (level: string) : Partial<BaseAnalytics> => ({
  screen_name: `Builder > Editor > ${level}`,
  event_category: 'Builder > Editor',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Editor',
  screen_level3: `Builder > Editor > ${level}`,
  screen_level4: `Builder > Editor > ${level}`,
  level2_breadcrumb: 'Builder > Editor',
  level3_aggregation: `Builder > Editor > ${level}`
});

export const onCartProductSaveClick = (
  label: string
) : Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.CART.NON_PIZZA_PRODUCT.SAVE_CLICK,
  event_action: label,
  event_label: `Builder > Editor > ${label}`,
  ...commonBuilderEditor('Non-Pizza Product')
});

export const onCartRailProductGoBack = (
  label: string
) : Partial<BaseAnalytics> => ({
  event: 'link_click',
  beacon_id: BeaconIds.CHECKOUT.CART.NON_PIZZA_PRODUCT.BACK_TO_CART_CLICK,
  event_action: label,
  event_label: `Builder > Editor > ${label}`,
  ...commonBuilderEditor('Non-Pizza Product')
});

export const onCartRailProductClose = (
  label: string
) : Partial<BaseAnalytics> => ({
  event: 'link_click',
  beacon_id: BeaconIds.CHECKOUT.CART.NON_PIZZA_PRODUCT.CLOSE_CLICK,
  event_action: label,
  event_label: `Builder > Editor > ${label}`,
  ...commonBuilderEditor('Non-Pizza Product')
});

export const onBackToCartDeliveryMinimum = (
  label: string,
  cartItems: CartItem[]
) : Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.CART.DELIVERY.CLOSE_BACK_TO_CART_BUTTON_MINIMUM,
  event_action: label,
  event_label: `Checkout > Cart > ${label}`,
  ...getDealsInfo(cartItems),
  ...commonCheckout('Cart')
});

export const onRemoveTip = (
  label: string,
  cartItems: CartItem[]
) : Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.TIP.REMOVE,
  event_action: label,
  event_label: `Checkout > Checkout My Order > ${label}`,
  ...getDealsInfo(cartItems),
  ...commonCheckout('Checkout My Order')
});

export const onAddTip = (
  label: string,
  cartItems: CartItem[]
) : Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.TIP.ADD,
  event_action: label,
  event_label: `Checkout > Checkout My Order > ${label}`,
  ...getDealsInfo(cartItems),
  ...commonCheckout('Checkout My Order')
});

export const onSelectPaymentOption = (
  label: string,
  cartItems: CartItem[]
) : Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.PAYMENT_OPTION.SELECT,
  event_action: label,
  event_label: `Checkout > Checkout My Order > ${label}`,
  ...getDealsInfo(cartItems),
  ...commonCheckout('Checkout My Order')
});

export const onOccasionDetailsSectionLinkClick = (
  label: string
) : Partial<BaseAnalytics> => ({
  event: 'link_click',
  beacon_id: BeaconIds.CHECKOUT.OCCASION_SECTION_ACTION_CLICK,
  event_action: label,
  event_label: `Checkout > Checkout My Order > ${label}`,
  ...commonCheckout('Checkout My Order')
});

export const onSignInClick = (): Partial<BaseAnalytics> => ({
  event: 'link_click',
  beacon_id: BeaconIds.CHECKOUT.SIGNIN_CLICK,
  event_action: 'Sign In', 
  event_label: 'Checkout > Checkout My Order > Sign In',
  ...commonCheckout('Checkout My Order')
});

export const onPromotionalEmailClick = (): Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.CONTACT_INFO.PROMOTIONAL_EMAILS,
  event_action: 'Yes Enroll In Email', 
  event_label: 'Checkout > Checkout My Order > Yes Enroll In Email',
  ...commonCheckout('Checkout My Order')
});

export const onTextNotificationsClick = (): Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.TEXT_NOTIFICATIONS,
  event_action: 'Yes Send me text notifications',
  event_label: 'Checkout > Checkout My Order > Yes Send me text notifications',
  ...commonCheckout('Checkout My Order')
});

export const onEditLinkClick = (): Partial<BaseAnalytics> => ({
  event: 'link_click',
  beacon_id: BeaconIds.CHECKOUT.EDIT_LINK_CLICK,
  event_action: EDIT_TITLE,
  event_label: `Checkout > Checkout My Order > ${EDIT_TITLE}`,
  ...commonCheckout('Checkout My Order')
});

export const onChangeLocalizedStore = (
  label: string, cartItems: CartItem[]
): Partial<BaseWarningCart> => ({
  event: 'modal_message',
  beacon_id: BeaconIds.CHECKOUT.CHANGE_OCCASION.OPEN_MODAL,
  event_action: CHANGE_OCCASION_TITLE,
  event_label: `Checkout > ${label} > Pop-Up Message > ${CHANGE_OCCASION_TITLE}`,
  modal_message: 'Do you want to proceed?',
  ...getDealsInfo(cartItems),
  ...commonCheckout(label)
});

export const onInteractWithChangeLocalizedStoreModal = (
  label: string, action: string, cartItems: CartItem[]
): Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.CHANGE_OCCASION.CLICK_CONTINUE_OR_CANCEL,
  event_action: action,
  event_label: `Checkout > ${label} > Pop-Up Message > ${CHANGE_OCCASION_TITLE} > ${action}`,
  ...getDealsInfo(cartItems),
  ...commonCheckout(label)
});

export const onPlaceOrderError = (
  errorMsg: string, cartItems: CartItem[]
): Partial<CheckoutBaseAnalytics> => ({
  event: 'error_message',
  event_action: `Place Your Order > ${errorMsg}`,
  event_label: `Checkout > Checkout My Order > Place Your Order > ${errorMsg}`,
  beacon_id: BeaconIds.CHECKOUT.PLACE_ORDER_ERROR,
  form_name: 'Checkout My Order',
  ...commonCheckout('Checkout My Order'),
  products: getProductsInfo(cartItems)
});

export const onPlaceOrderSuccess = (cartItems: CartItem[]): Partial<BaseAnalytics> => ({
  event: 'button_click',
  event_action: 'Place Your Order > Success',
  event_label: 'Checkout > Checkout My Order > Place Your Order > Success',
  beacon_id: BeaconIds.CHECKOUT.PLACE_ORDER_SUCCESS, // '51.08.02'
  form_name: 'Checkout My Order',
  ...getDealsInfo(cartItems),
  ...commonCheckout('Checkout My Order')
});

export const onClickYesToEnrollForHutRewards = (label: string): Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.HUT_REWARDS.ENROLL,
  event_action: label,
  event_label: `Checkout > Checkout My Order > ${label}`,
  ...commonCheckout('Checkout My Order')
});

export const onSuccessEnrollIntoHutRewards = (label: string): Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.HUT_REWARDS.SUCCESS,
  event_action: label,
  event_label: `Checkout > Checkout My Order > ${label}`,
  ...commonCheckout('Checkout My Order')
});

export const onClickJoinHutRewardsOrderConfirmation = (label: string): Partial<BaseAnalytics> => ({
  event: 'button_click',
  beacon_id: BeaconIds.CHECKOUT.HUT_REWARDS.ENROLL_ORDER_CONFIRMATION,
  event_action: label,
  event_label: `Checkout > Order Confirmation > ${label}`,
  ...commonCheckout('Order Confirmation')
});

export const onCheckOutPageView = (
  cartItems: CartItem[]
): Partial<CheckoutBaseAnalytics> => ({
  event: 'screen_view',
  form_name: 'Checkout My Order',
  product_action: 'checkout',
  beacon_id: BeaconIds.CHECKOUT.INITIAL_LOAD,
  checkout_step: '4',
  products: getProductsInfo(cartItems),
  ...getDealsInfo(cartItems),
  ...commonCheckout('Checkout My Order')
});

export const onOrderConfirmationPageView = (
  cartItems: CartItem[] | undefined,
  isDelivery: boolean,
  promiseTime: string,
  storeId: string,
  orderAnalytics: Partial<OrderConfirmedAnalytics>
): Partial<OrderConfirmedAnalytics> => ({
  ...orderAnalytics,
  event: 'screen_view',
  form_name: 'Checkout My Order',
  product_action: 'purchase',
  checkout_step: '5',
  beacon_id: BeaconIds.ORDER_CONFIRMATION.INITIAL_LOAD,
  transaction_affiliation: storeId,
  currency_code: 'USD',
  products: getProductsInfo(cartItems),
  ...getDealsInfo(cartItems),
  ...commonCheckout('Order Confirmation'),
  ...finalPromiseTime(isDelivery, promiseTime),
  products_sold_count: getItemQuantityByType(cartItems, 'PRODUCT'),
  pizzas_sold_count: getItemQuantityByType(cartItems, 'PIZZA'),
  order_coupon_redemptions: getCouponQuantity(cartItems),
  ...getCouponInformation(cartItems)
});

export const onCartRailPageView = (
  cartItems: CartItem[] = [], cartViews: string
): Partial<CheckoutBaseAnalytics> => ({
  beacon_id: BeaconIds.CHECKOUT.CART.RAIL,
  event: 'screen_view',
  screen_name: 'Checkout > Cart',
  screen_level1: 'Checkout',
  screen_level2: 'Checkout > Cart',
  screen_level3: 'Checkout > Cart',
  screen_level4: 'Checkout > Cart',
  level2_breadcrumb: 'Checkout > Cart',
  level3_aggregation: 'Checkout > Cart',
  product_action: 'checkout',
  checkout_step: '1',
  cart_views: cartViews,
  products: getProductsInfo(cartItems),
  ...getDealsInfo(cartItems)
});

export const onRailEditLinkClick = (
  event: string, cartItems: CartItem[]
): Partial<CheckoutBaseAnalytics> => ({
  beacon_id: BeaconIds.CHECKOUT.CART.EDIT_LINKS,
  event: 'link_click',
  event_category: 'Checkout > Cart',
  event_action: event,
  event_label: `Checkout > Cart > ${event}`,
  screen_name: 'Checkout > Cart',
  screen_level1: 'Checkout',
  screen_level2: 'Checkout > Cart',
  screen_level3: 'Checkout > Cart',
  screen_level4: 'Checkout > Cart',
  level2_breadcrumb: 'Checkout > Cart',
  level3_aggregation: 'Checkout > Cart',
  ...getDealsInfo(cartItems)
});

export const onRailGoToCheckoutClick = (
  cartItems: CartItem[] = []
): Partial<CheckoutBaseAnalytics> => ({
  beacon_id: BeaconIds.CHECKOUT.CART.GO_TO_CHECKOUT,
  event: 'button_click',
  event_category: 'Checkout > Cart',
  event_action: 'Go To Checkout',
  event_label: 'Checkout > Cart > Go To Checkout',
  screen_name: 'Checkout > Cart',
  screen_level1: 'Checkout',
  screen_level2: 'Checkout > Cart',
  screen_level3: 'Checkout > Cart',
  screen_level4: 'Checkout > Cart',
  level2_breadcrumb: 'Checkout > Cart',
  level3_aggregation: 'Checkout > Cart',
  product_action: 'checkout',
  checkout_step: '2',
  products: getProductsInfo(cartItems),
  ...getDealsInfo(cartItems)
});

export const roktLoaded = (): Partial<BaseAnalytics> => ({
  beacon_id: BeaconIds.ORDER_CONFIRMATION.ROKT_LOAD, // '53.12.02'
  event: 'rokt_load',
  ...commonCheckout('Order Confirmation'),
  event_action: 'ROKT Load',
  event_label: 'Checkout > Order Confirmation > ROKT Load'
});

export const roktLoadedCompleted = (): Partial<BaseAnalytics> => ({
  beacon_id: BeaconIds.ORDER_CONFIRMATION.ROKT_COMPLETED, // '53.14.02'
  event: 'rokt_completed',
  ...commonCheckout('Order Confirmation'),
  event_action: 'ROKT Completed',
  event_label: 'Checkout > Order Confirmation > ROKT Completed'
});

export const onYumPaymentIframeVisible = () : Partial<BaseAnalytics> => ({
  event: 'promo_impression',
  event_action: 'Yum Pay Impression',
  event_label: 'Checkout > Checkout My Order > Yum Pay Impression',
  beacon_id: BeaconIds.CHECKOUT.YUM_PAYMENT.PROMO_IMPRESSION,
  ...commonCheckout('Checkout My Order')
});

export const onYumPaymentIframeLoadFail = (): Partial<BaseAnalytics> => ({
  beacon_id: BeaconIds.CHECKOUT.YUM_PAYMENT.IFRAME_LOAD_FAIL,
  event_name: 'yum_payment_failure',
  screen_name: 'checkout > checkout my order',
  event_action: 'Yum Payment Failure',
  event_label: 'Checkout > Checkout My Order > Yum Payment Failure',
  event_category: 'Yum Payment Failure'
});

export const onCheckoutSignInSuccess = (): Partial<BaseAnalytics> => ({
  event_name: 'sign_in',
  beacon_id: BeaconIds.CHECKOUT.SIGN_IN.SUCCESS,
  event_action: 'Sign In Form Success',
  event_label: 'Checkout > Sign In Form > Sign In Form Success',
  ...commonCheckout('Sign In Form')
});
export const onCheckoutSignInErrors = (errorMessage = ''): Partial<ErrorAnalytics> => ({
  event_name: 'error_message',
  beacon_id: BeaconIds.CHECKOUT.SIGN_IN.ERRORS,
  event_action: `Error > ${errorMessage}`,
  event_label: `Checkout > Sign In Form > Error > ${errorMessage}`,
  error_message: errorMessage,
  ...commonCheckout('Sign In Form')
});
