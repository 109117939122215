import {
  BelowLineItemPromotionEffect,
  Cart,
  CartWarning,
  ItemLevelPromotionEffect,
  ItemPresenceRequirement,
  orderApiCart,
  OrderLevelPromotionEffect,
  PromotedItem,
  PromotedItemBundle,
  PromotedItemProduct,
  PromotedItemProductVariant,
  PromotionDefinition,
  PromotionEffect,
  PromotionNotAppliedReasonType,
  PromotionNotAppliedWarning,
  PromotionRequirement,
  YumCart
} from '@pizza-hut-us-development/client-core';
import { Dispatch } from '@reduxjs/toolkit';
import { getOrInitializeOptimizely } from '../../../../../../../../../optimizely/optimizely';
import tel from '@/telemetry';

export const isYumCart = (cart: Cart): cart is Cart & YumCart => 'belowLineItems' in cart;

export const isRedemptionWarning = (warning?: CartWarning): warning is CartWarning & PromotionNotAppliedWarning => {
  if (!warning) return false;
  return 'promotionId' in warning;
};

export const findPromoFromWarning = (cart: YumCart): string | undefined => (cart.warnings?.find((warning) => isRedemptionWarning(warning)) as PromotionNotAppliedWarning)?.promotionId;

export const findRedemptionWarningFromPromoOrCode = (cart: YumCart, promotionId?: string, code?: string): PromotionNotAppliedWarning | undefined => {
  const optimizely = getOrInitializeOptimizely();
  const isLoyaltyWarningFixEnabled = optimizely?.isFeatureEnabled('fr-web-4611-fix-loyalty-redemption-warning');
  const allAssociatedWarnings = cart.warnings?.filter((warning) => {
    if (isRedemptionWarning(warning)) return warning.code === code || warning.promotionId === promotionId;
    return false;
  }) as PromotionNotAppliedWarning[];

  let associatedWarning = allAssociatedWarnings[0] ?? undefined;

  // Loyalty redemptions will return 2 warnings, the first has no reason data, so leverage the 2nd in this case
  const firstWarningReason = allAssociatedWarnings[0]?.reasons[0];
  if (isLoyaltyWarningFixEnabled && allAssociatedWarnings.length >= 2 && firstWarningReason?.message === 'PROMOTION_NOT_APPLIED' && !firstWarningReason?.__typename) {
    associatedWarning = allAssociatedWarnings[1];
  };

  return associatedWarning;
};

// PromotionRequirement type guard
export const isItemPresenceRequirement = (req: PromotionRequirement): req is PromotionRequirement & ItemPresenceRequirement => 'items' in req;

// PromotionEffect type guard
export const isItemLevelPromotionEffect = (effect: PromotionEffect): effect is PromotionEffect & ItemLevelPromotionEffect => 'items' in effect;
export const isBelowLineItemPromotionEffect = (effect: PromotionEffect): effect is PromotionEffect & BelowLineItemPromotionEffect => 'belowLineItemType' in effect;
export const isOrderLevelPromotionEffect = (effect: PromotionEffect): effect is PromotionEffect & OrderLevelPromotionEffect => 'discountDistributionConfig' in effect || 'includedItems' in effect || 'excludedItems' in effect;

// PromotedItem type guards
export const isPromotedItemProduct = (item: PromotedItem): item is PromotedItemProduct => 'productCode' in item && !('productVariantCode' in item);
export const isPromotedItemProductVariant = (item: PromotedItem): item is PromotedItemProductVariant => 'productVariantCode' in item;
export const isPromotedItemBundle = (item: PromotedItem): item is PromotedItemBundle => 'bundleCode' in item;

// Is a warning that we should display in the cart, until the user resolves it
// ie. Not a warning that will never work, OR one that the user will have to re-add
export const isResolvableWarning = (promotionWarning: PromotionNotAppliedWarning): boolean => {
  const optimizely = getOrInitializeOptimizely();

  const warningReasonTypes = promotionWarning.reasons.map((reason) => reason.__typename);
  const nonResolvableTypes = warningReasonTypes.filter((type) => {
    // Resolvable warnings
    // TODO: Remove typecast when CC type bug is fixed
    if (type as any === PromotionNotAppliedReasonType.ItemPresenceRequirementNotMet) return false;
    if (type === PromotionNotAppliedReasonType.ItemQuantityRequirementNotMet) return false;
    if (type === PromotionNotAppliedReasonType.SubtotalRequirementNotMet) return false;
    if (type === PromotionNotAppliedReasonType.PromotionEffectTargetNotInCart) return false;
    if (type === PromotionNotAppliedReasonType.SubtotalNotDiscountable && optimizely?.isFeatureEnabled('fr-web-4361-promo-code-handling')) return false;
    if (type === PromotionNotAppliedReasonType.PromotionIsInvalid) {
      const associatedReason = promotionWarning.reasons.find((reason) => reason.__typename === PromotionNotAppliedReasonType.PromotionIsInvalid);
      if (associatedReason?.message === 'NO_DISCOUNTABLE_SUBTOTAL') return false;
    }
    return true;
  });

  return nonResolvableTypes.length === 0;
};

export const getPromotionDefinition = async (promotionId: string, cart: Cart, dispatch: Dispatch<any>, existingDefinition?: PromotionDefinition) => {
  let promotionDefinition = existingDefinition;
  // Check for our definition already in the cart object
  if (!promotionDefinition && cart.promotionDefinitions) {
    promotionDefinition = cart.promotionDefinitions.find((definitionEntry) => definitionEntry.promotionId === promotionId);
  }
  // If we don't find it there, grab it directly from the endpoint
  if (!promotionDefinition) {
    // Find our associated promotion definition
    const response = await dispatch(orderApiCart.endpoints.getPromotion.initiate(promotionId)) as unknown as { data: PromotionDefinition };
    if (response.data) {
      tel.addCustomEvent(tel.CUSTOM_EVENT_NAME.CART.PROMO.LOOKUP, 'success', {
        id: promotionId
      });
      promotionDefinition = response.data;
    } else {
      tel.addCustomEvent(tel.CUSTOM_EVENT_NAME.CART.PROMO.LOOKUP, 'failure', {
        id: promotionId
      });
    }
  }

  return promotionDefinition;
};
